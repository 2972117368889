.content-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.img-align {
    display: flex;
    justify-content: center;
}

@media (max-width: 991px){
    .content-block {
        flex-direction: column;
        padding-bottom: 50px;
    }

    .content-block-right {
        flex-direction: column-reverse !important;
    }

    .img-align img {
        padding: 0 16px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .content-block {
        flex-direction: column;
        padding-bottom: 50px;
    }

    .content-block-right {
        flex-direction: column-reverse !important;
    }
}