.technology-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px; 
    padding: 20px;
  }
  
  /* Styles for mobile and tablet devices */
@media screen and (max-width: 1023px) {
  .technology-card-container {
    width: 100%;
    justify-content: center;
  }	
}