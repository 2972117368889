.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-left: 30px;
}

.back-arrow {
  margin-right: 8px;
}
