.opportunity-hub-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 235px;
  height: 325px;
  border-radius: 8px;
  background: #fafafa;
  padding: 30px 15px;
  box-shadow: 0px 0px 30px 10px rgba(148, 148, 148, 0.1);
  border: 1px solid #d6d6d6;
}

.opportunity-hub-card h4 {
  color: #011561;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px !important;
  line-height: 24px;
  margin-inline: 0px;
}

.opportunity-hub-card span {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #72cc00;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.opportunity-hub-card-regime-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.opportunity-hub-card-regime {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #c2f7ff;
  color: #19606b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.opportunity-hub-card-contract {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.opportunity-hub-card-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* Styles for mobile and tablets */
@media screen and (max-width: 1023px) {
  .opportunity-hub-card {
    margin: 20px 0;
  }
}
