.job-listing {
  padding: 2rem;
  padding-right: 0;
}

.job-title {
  font-size: 42px;
  margin-bottom: 1rem;
}

.intro-text,
.call-to-action {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.job-listing > h2,
.job-listing > h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.requirements,
.responsibilities,
.offer {
  font-size: 1.2rem;
}

.call-to-action {
  margin-top: 40px;
}

.requirements > ul,
.responsibilities > ul,
.offer > ul {
  margin: 20px 0;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .job-listing {
    padding: 1.5rem;
    margin: 1rem;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .job-listing {
    padding: 1.5rem;
  }

  .intro-text,
  .call-to-action {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .call-to-action {
    margin-bottom: 1rem;
  }
}
