.know-how-card-container {
    display: flex;
    flex-direction: row;
    gap: 0;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .slick-track {
    gap: 0px !important;
  }
  
/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .know-how-card-container {
    margin-top: 10px;
    margin-bottom: 80px;
  }
}