.our-technology-card {
    position: relative;
    width: 100%;
    max-width: 350px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px; 

  }
  
  .card-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .card-title-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(2, 39, 176, 0.6);
    text-align: center;
    padding: 15px 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .card-title {
    color: white;
    font-size: 1.25rem;
    margin: 0;
  }

  .our-technology-card-selected {
    box-shadow: 0 0 20px 10px rgba(2, 39, 176, 0.6);
    border-radius: 8px; 
  }
  
  /* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .our-technology-card {
    width: 85%;
  }
}