.know-how-card {
  /* width: 30%; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* overflow: hidden; */
}

div:has(> .know-how-card) {
  width: 100%;
}

.know-how-card-top {
  position: relative;
}

.know-how-card-image {
  width: 140px;
  height: 100px;
  top: 0;
  left: 25%;
  position: relative;
  padding: 15px;
}

.know-how-card-center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3552c0;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
}

.know-how-card-left-element {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  border-color: #e7e7e7;
  padding: 0px;
  /* border-radius: 5px; */
  border-bottom-style: solid;
  /* border-top-width: 0px; */
  width: 50%;
  border-width: 2px;
  z-index: 0;
}

.know-how-card-right-element {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  border-color: #e7e7e7;
  padding: 0px;
  /* border-radius: 5px; */
  border-bottom-style: solid;
  /* border-top-width: 0px; */
  width: 50%;
  border-width: 2px;
  z-index: 0;
}

.know-how-card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.know-how-card-title {
  font-size: 20px;
  margin-bottom: 16px;
}

.know-how-card-description {
  font-size: 14px;
  color: #666;
  text-align: center;
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .know-how-card-image {
    left: 30%;
  }
}
