.weAreLookingForNimbers-container {
    display: flex;
    flex-shrink: 0;
    padding: 0 100px;
    background-color: #171717;
    flex-direction: column;
}

.weAreLookingForNimbers-content-row {
    display: flex;
    flex-direction: row;
}

.weAreLookingForNimbers-content-item {
    color: #FAFAFA;
}

.weAreLookingForNimbers-content-column {
    width: 25%;
    padding: 25px
}

.weAreLookingForNimbers-content-item h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
}

.weAreLookingForNimbers-content-item p {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.weAreLookingForNimbers-content-right {
    justify-content: flex-end;
}

.rotate-img {
    animation: rotate 10s linear infinite; /* Apply the rotate animation infinitely */
    transform-origin: center center;
}

.rotate-img:hover {
    cursor: pointer;
    border-radius: 50%;
    content: url("/public/images/LetsTalkWhite.png");
}

@keyframes rotate {
    0% {
        transform: rotate(0deg); /* Start the rotation from 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* End the rotation at 360 degrees */
    }
}

@media only screen and (max-width: 1200px) {
    .weAreLookingForNimbers-content-row {
        flex-direction: column;
        gap: 24px;
    }

    .weAreLookingForNimbers-content-column {
        width: 100%;
        padding: 5px;
    }

    .weAreLookingForNimbers-container {
        padding: 80px 16px 16px 16px;
        gap: 24px;
    }
}

/* Mobile */
@media (max-width: 991px) {
    .weAreLookingForNimbers-container {
        padding: 40px 16px 0 16px; 
    }

    .weAreLookingForNimbers-content-row {
        flex-direction: column; 
        gap: 16px; 
    }

    .weAreLookingForNimbers-content-column {
        width: 100%; 
        padding: 10px; 
    }

    .weAreLookingForNimbers-content-right {
        justify-content: center; 
    }

    .rotate-img {
        width: 100px; 
        height: 100px;
    }

    .weAreLookingForNimbers-content-item h1 {
        font-size: 30px; 
    }
}

/* Tablet */
@media (min-width: 992px) and (max-width: 1200px) {
    .weAreLookingForNimbers-container {
        padding: 80px 16px; 
    }

    .weAreLookingForNimbers-content-row {
        flex-direction: column; 
        gap: 24px; 
    }

    .weAreLookingForNimbers-content-column {
        width: 100%; 
        padding: 16px; 
    }

    .weAreLookingForNimbers-content-right {
        justify-content: flex-end; 
    }
}
