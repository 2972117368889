.ourValues {
  display: flex;
  gap: 24px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.ourValues-img {
  display: flex;
  width: 251px;
  height: 257px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ourValues-text {
  align-self: stretch;
  color: #011561;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

@media (max-width: 991px) {
  .ourValues {
    flex-direction: column;
    width: 90%;
    padding-left: 40px;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .ourValues {
    flex-direction: column;
  }
}

/* Styles for mobile and tablets */
@media screen and (max-width: 1023px) {
  .ourValues {
    margin: 30px 0;
  }
}
