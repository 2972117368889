.nimber-talent-block {
  display: flex;
  padding: 64px 64px 16px 64px;
  gap: 20px;
  justify-content: center;
}

.nimber-talent-left {
  width: 50%;
}

.slick-slide {
  cursor: grab;
}

.nimber-slider-max {
  width: 100%;
  margin-bottom: 50px;
}

.nimber-main-title {
  font-family: "Brink-Regular", serif;
  font-weight: 700;
  font-size: 60px;
  color: #171717;
}

.nimber-secondary-text {
  font-family: "Brink-Regular", serif;
  font-size: 20px;
  color: #171717;
  line-height: 32px;
}

.nimber-flex-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .slick-track {
    display: block !important;
  }

  .nimber-slider-max {
    width: 95%;
  }

  .nimber-main-title {
    font-family: "Brink-Regular", serif;
    font-weight: bold;
    font-size: 35px;
    color: #171717;
  }

  .nimber-secondary-text {
    font-family: "Brink-Regular", serif;
    font-size: 22px;
    color: #171717;
    line-height: 24px;
    margin-top: 24px;
  }

  .nimber-talent-block {
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }
  .nimber-talent-left {
    width: 100%;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .slick-track {
    display: block !important;
  }

  .nimber-slider-max {
    width: 95%;
  }

  .nimber-main-title {
    font-family: "Brink-Regular", serif;
    font-weight: bold;
    font-size: 30px;
    color: #171717;
  }

  .nimber-secondary-text {
    font-family: "Brink-Regular", serif;
    font-size: 18px;
    color: #171717;
    line-height: 24px;
    margin-top: 24px;
  }

  .nimber-talent-block {
    flex-direction: column;
    padding: 20px;
  }
  .nimber-talent-left {
    width: 100%;
  }
}

/*
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nimber-talent-block {
        flex-direction: column;
    }

    .nimber-talent-left {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .nimber-talent-block {
        flex-direction: column;
        padding: 20px;
    }

    .nimber-talent-img {
        display: none;
    }

    .nimber-talent-left {
        width: 100%;
    }
}
*/
