.techedge-card {
    display: flex;
    width: 302px;
    height: 500px;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0 0 30px 10px rgba(148, 148, 148, 0.10);
    color: #949494;
    justify-content: center !important;
    align-items: center !important;
}

.techedge-card-selected {
    display: flex;
    width: 302px;
    height: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 0 30px 10px rgba(148, 148, 148, 0.10);
    border-radius: 16px;
}

 .techedge-image {
    width: 302px;
    height: 238px;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    background-color: lightgray;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.techedge-image-selected {
    width: 298px;
    height: 238px;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: hidden;
    border-left-style: solid;
    border-top-color: rgb(1, 21, 97);
    border-right-color: rgb(1, 21, 97);
    border-bottom-color: rgb(1, 21, 97);
    border-left-color: rgb(1, 21, 97);
    background-color: lightgray;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

 .techedge-card-content {
    display: flex;
    padding: 24px 24px 0 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 0px 0px 16px 16px;
    border: 1px solid #D4D4D4;
    background: #FAFAFA;
}

.techedge-card-title {
    align-self: stretch;
    color: #949494;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; 
}

.techedge-card-title-selected {
    align-self: stretch;
    color: #011561;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.techedge-description {
    flex: 1 0 0;
    align-self: stretch;
    color: #949494;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.techedge-description-selected {
    flex: 1 0 0;
    align-self: stretch;
    color: #171717;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}