.nearshore-section02 {
  background-color: #ffffff;
}

.nearshore-section02a {
  display: flex;
  justify-content: center;
}

.nearshore-section02a-title {
  width: 50%;
}

.nearshore-section02a-text02 {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #171717;
  width: 50%;
}

.nearshore-section03a {
  width: 45%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nearshore-section03a-text02 {
  font-size: 26px;
}

.nearshore-section03b {
  margin-top: 100px;
}

.nearshore-section-title {
  margin-top: 170px;
}

.nearshore-section-title-text {
  font-family: Brink-Regular, serif;
  margin-left: -20px;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #171717;
  margin-bottom: 10px;
  margin-top: 0;
}

.nearshore-thriving-text {
  font-size: 26px;
}

.nearshore-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nearshore-three-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
}

.nearshore-single-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  width: 33.3%;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.nearshore-single-block-large {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  width: 50%;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.nearshore-single-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  min-width: 360px;
  max-width: 360px;
}

.nearshore-single-item-title {
  color: #011561;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.nearshore-single-item-description {
  color: #171717;
  font-size: 20px;
  line-height: 32px;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .nearshore-section02 {
    margin-top: 50px;
  }

  .nearshore-section02a {
    padding: 0 10px;
  }

  .nearshore-why-nearshoring-text {
    color: #171717;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    font-size: 22px;
    padding: 20px;
    width: 100%;
  }

  .nearshore-section02b {
    margin: 0 50px;
  }

  .nearshore-whypt-img > img {
    padding: 0 16px 0 0;
    margin-top: 50px;
    max-width: 85%;
  }

  .nearshore-section03a {
    width: 100%;
    padding: 30px 10px 0 10px;
  }

  .nearshore-section03b {
    margin-top: 0;
  }

  .nearshore-three-block {
    margin: 20px;
  }

  .nearshore-single-item {
    padding: 0;
  }

  .nearshore-section03b > .nearshore-three-block {
    flex-direction: column;
    align-items: center;
    margin: 0 20px 20px 20px;
  }

  .nearshore-thriving-text {
    font-size: 22px;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .nearshore-section02 {
    margin-top: 50px;
  }

  .nearshore-section02a {
    flex-direction: column;
    width: 100%;
  }

  .nearshore-section02a-title {
    width: 100%;
  }

  .nearshore-section02a-text02 {
    width: 100%;
  }

  .nearshore-why-nearshoring-text {
    color: #171717;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    font-size: 18px;
    padding: 0 30px 30px 30px;
    width: 100%;
  }

  .nearshore-three-block {
    flex-direction: column;
    margin: 20px;
  }

  .nearshore-single-block,
  .nearshore-single-block-large {
    width: 100%;
  }

  .nearshore-whypt-img > img {
    padding: 0 16px 0 0;
    margin-top: 50px;
    max-width: 100%;
  }

  .nearshore-section03a {
    width: 100%;
    padding: 30px 0 0 0;
  }

  .nearshore-section03a-text02 {
    padding: 30px 30px 0 30px !important;
  }

  .nearshore-section03b {
    margin-top: 0;
  }

  .nearshore-section03b > .nearshore-three-block {
    align-items: center;
  }

  .nearshore-section-title {
    margin-top: 50px;
  }

  .nearshore-thriving-text {
    font-size: 18px;
    padding: 30px;
  }
}
