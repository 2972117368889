/* Header.css */
.header {
  position: relative;
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #fafafa;
}

.header-no-video {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #fafafa;
}

.logo {
  height: 30px;
}

.menu {
  position: absolute;
  right: 8%;
}

.menu-item {
  display: inline-block;
  margin-left: 20px;
}

.header-menu-row {
  position: relative;
  height: 80px;
  background: #ffffff25;
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 1;
}

.header-block-text {
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 0px 80px;
  justify-content: center;
}

.header-menu-item-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fafafa;
  text-decoration: none;
  font-family: "Brink-Thin";
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.header-top-text {
  font-family: "Brink-Bold", Arial, sans-serif;
  font-size: 30px;
  color: #7cedff;
}

.header-top-title {
  font-family: "Brink-Bold", Arial, sans-serif;
  font-size: 60px;
  width: 90%;
  color: #fafafa;
}

.header-top-subtext {
  font-family: "Brink-Regular", Arial, sans-serif;
  font-size: 20px;
  line-height: 40px;
  color: #62e9ff;
}

.logo-holder {
  width: 100vw;
  padding-left: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#menu-toggle,
.menu-icon {
  display: none; /* Hide the checkbox */
}

.header-block-text .header-button {
  cursor: pointer;
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .header-top-title {
    margin: 0 0 10px 0;
  }
  .header-top-subtext {
    font-size: 24px;
  }

  .header-block-text .header-button {
    margin: 40px 0;
    padding: 30px 0;
  }

  .techedge-header-btn {
    margin: 20px 0 !important;
  }

  .nearshore-header-btn {
    margin: 20px 0 !important;
    padding: 30px 0 !important;
  }

  .nearshore-header-top-title {
    margin-bottom: 0;
  }
}

/* Styles for high-resolution screens */
@media screen and (min-width: 1920px) {
  .header-top-title {
    margin: 0 0 80px 0;
  }
  .header-top-subtext {
    font-size: 30px;
  }

  .header-block-text .header-button {
    margin: 0px 0 60px 0;
    padding: 30px 0;
  }

  .techedge-header-btn {
    margin: 40px 0 !important;
  }

  .nearshore-header-btn {
    margin: 0px 0 40px 0 !important;
  }

  .nearshore-header-top-title {
    margin: 0 0 40px 0 !important;
  }
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .header {
    height: 30vh;
  }

  .header-top-title {
    font-size: 30px;
    width: 100%;
  }

  .header-block-text {
    vertical-align: center;
    padding: 20px 16px 40px 16px;
  }

  .header-top-subtext {
    font-size: 18px;
  }

  .header-top-text {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .logo-holder {
    padding: 10px 0 10px 16px;
  }

  .logo-holder > img {
    margin-top: 7px;
  }

  .logo-holder > a {
    padding-top: 7px;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
  }

  .menu-toggle {
    display: none; /* Hide the checkbox */
  }

  .menu-icon {
    display: block; /* Ensure the hamburger icon is visible */
    cursor: pointer;
    color: white;
    font-size: 24px;
    margin-right: 20px;
  }

  .menu-item {
    padding: 12px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid lightgray; /* White border bottom */
  }

  .menu-item:hover {
    background-color: #555;
  }

  .menu-item.header-menu-item-selected {
    background-color: #555;
  }

  /* Show menu when checkbox is checked */
  #menu-toggle:checked ~ .menu {
    /* Changed "+" to "~" to target the sibling menu */
    display: flex; /* Display the menu when the checkbox is checked */
    width: 50%;
    margin-left: 50%;
  }

  .menu-item-selected-bar {
    display: none;
  }

  .header-block-text .header-button {
    height: 40px;
    margin: 10px 0;
  }

  .nearshore-header-btn {
    margin: 5px 0 0 0 !important;
  }

  .nearshore-header-top-text {
    margin-bottom: 0;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .header-top-title {
    font-size: 30px;
    width: 100%;
  }

  .header-block-text {
    vertical-align: center;
    padding: 0px 16px 10px 16px;
  }

  .header-top-subtext {
    font-size: 18px;
    margin-top: 20px;
  }

  .header-top-text {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .logo-holder {
    padding-left: 16px;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
  }

  .menu-toggle {
    display: none; /* Hide the checkbox */
  }

  .menu-icon {
    display: block; /* Ensure the hamburger icon is visible */
    cursor: pointer;
    color: white;
    font-size: 24px;
    margin-right: 20px;
  }

  .menu-item {
    padding: 12px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid lightgray; /* White border bottom */
  }

  .menu-item:hover {
    background-color: #555;
  }

  .menu-item.header-menu-item-selected {
    background-color: #555;
  }

  /* Show menu when checkbox is checked */
  #menu-toggle:checked ~ .menu {
    /* Changed "+" to "~" to target the sibling menu */
    display: flex; /* Display the menu when the checkbox is checked */
  }

  .menu-item-selected-bar {
    display: none;
  }

  .header-block-text .header-button {
    margin: 30px 0 10px 0;
    padding: 5px;
    width: 50%;
  }

  .techedge-header-btn {
    width: 70% !important;
  }

  .nearshore-header-btn,
  .opportunity-hub-header-btn {
    width: 70% !important;
    margin: 20px 0 0 0 !important;
  }
}

/*
@media (max-width: 910px) {
    .header-top-title {
        font-size: 30px;
        width: 100%;
    }

    .header-block-text {
        vertical-align: center;
        padding: 40px 16px;
    }

    .header-top-subtext {
        font-size: 14px;
    }

    .header-top-text {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .logo-holder {
        padding-left: 16px;
    }
}*/

.header-menu-item-selected {
  color: #3be4ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.menu-item-selected-bar {
  position: absolute;
  top: 8px;
  letter-spacing: 0px;
}
