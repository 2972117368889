.section-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 64px;

    .section-title-text {
        font-family: 'Brink-Bold', Arial, sans-serif;
        margin-left: -10px;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #01104A;
    }
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) and (max-width: 1919px){

}

/* Styles for high-resolution screens */
@media screen and (min-width: 1920px) {

}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
    .section-title .section-title-text {
        font-size: 35px;
    }

    .section-title {
        padding: 16px;
    }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
    .section-title .section-title-text {
        font-size: 30px;
    }

    .section-title {
        padding: 16px;
    }
}

/*
@media (max-width: 991px){
    .section-title .section-title-text {
        font-size: 40px;
    }

    .section-title {
        padding: 0 16px;
        margin-bottom: 24px;
    }
}*/