.opportunity-hub-component {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 100;
  flex-shrink: 0;
  background: #fafafa;
}

.opportunity-hub-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.opportunity-hub-search-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.opportunity-hub-search-input {
  display: flex;
  width: 460px;
  height: 56px;
  flex-direction: column;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

.opportunity-hub-button-filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px;
  gap: 8px;
}

.opportunity-hub-btn-filter {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  height: 48px;
  cursor: pointer;
}

.opportunity-hub-btn-filter-unselected {
  border: 1px solid #d6d6d6;
  background: #fafafa;
  color: #0a0a0a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.opportunity-hub-btn-filter-selected {
  background: #0227b0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.opportunity-hub-or-search-for {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 285.714% */
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
}

.opportunity-hub-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 70vw;
}

/*
@media (max-width: 991px) {
    .opportunity-hub-search-section {
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }

    .opportunity-hub-search-input {
        display: flex;
        width: 100%;
        height: 56px;
        flex-direction: column;
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .icon-button {
        justify-content: center;
    }
}*/

.slick-arrow {
  display: flex !important;
  background-color: lightgray !important;
  border-radius: 2px;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.slick-prev:before {
  margin-top: 2px;
  font-size: 25px;
}

.slick-next:before {
  margin-top: 2px;
  font-size: 25px;
}

.slick-dots {
  bottom: -60px;
}

/* Styles for desktop screens */
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .opportunity-hub-cards .slick-slider {
    width: 90%;
  }
}

/* Styles for high-resolution screens */
@media screen and (min-width: 1920px) {
  .opportunity-hub-cards .slick-slider {
    width: 80%;
  }
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .opportunity-hub-cards .slick-slider {
    width: 112%;
  }
  .slick-dots {
    bottom: -40px;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .opportunity-hub-cards .slick-slider {
    width: 110%;
  }
  .slick-dots {
    bottom: -45px;
  }

  .opportunity-hub-search-section {
    width: 100%;
    margin-top: 20px;
  }

  .opportunity-hub-search-input {
    width: 70%;
    font-size: 16px;
  }

  .opportunity-hub-search-section .icon-button i {
    margin-left: 0px;
  }

  .opportunity-hub-button-filters {
    padding: 20px;
  }
}
