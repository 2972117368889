.schedule-wrapper {
  display: flex;
}

.contact-box {
  display: flex;
  width: 400px;
  background: #fafafa;
  padding: 26px 36px;
}

.contact-box img {
  margin-right: 32px;
}

.contact-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.call-schedule-text {
  color: #171717;
  font-size: 50px;
  font-weight: 700;
}

.schedule-call-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.schedule-call-image {
  background-color: white;
  padding-top: 106px;
  padding-bottom: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.schedule-call-image h3 {
  font-family: "Brink-Bold";
  color: #01104a;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 69px;
}

.schedule-call-title-image {
  display: flex;
  flex-direction: row;
}

.schedule-call-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.schedule-call-name {
  color: #0227b0;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.schedule-call-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.schedule-call-form {
  padding-top: 216px;
  padding-bottom: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.schedule-call-form form h3 {
  color: #171717;
  font-family: "Brink-Bold";
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.schedule-call-form-space-24 {
  margin-bottom: 24px;
}

.schedule-call-form-group {
  display: flex;
  flex-direction: column;
}

.schedule-call-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.schedule-call-form-group input {
  width: 100%;
  padding: 0 0 0 16px;
  height: 48px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #646464;
}

.schedule-call-form-group button {
  display: flex;
  height: 51px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px;
  background: #0227b0;

  color: #fafafa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .schedule-call-container {
    flex-direction: column;
  }

  .schedule-call-image {
    padding-bottom: 50px;
    width: 100%;
  }

  .schedule-call-form {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .schedule-call-image h3 {
    width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }

  .schedule-call-form-group input {
    color: #171717;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }
  .schedule-call-form {
    width: 100%;
  }
}
