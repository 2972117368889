.title-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-envelop {
    width: 100%;
    background: #171717;
    display: flex;
    justify-content: center;
}

.clients-wrapper {
    width: 100%;
    background: #FAFAFA;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.section02b-container{
    padding-bottom: 30px;
 }

.section02c-container{
    padding-bottom: 30px;
    padding-top: 50px;
 }

/* Styles for desktop screens */
@media screen and (min-width: 1024px) and (max-width: 1919px){

}

/* Styles for high-resolution screens */
@media screen and (min-width: 1920px) {

}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .section01{
        margin-top: 20px;
     }

     .section02b-container{
        padding-bottom: 0px;
     }
    
    .section02c-container{
        padding-bottom: 0px;
        padding-top: 0px;
     }
    
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .section01{
        margin-top: 20px;
     }

    .section02b-container{
        padding-bottom: 0px;
     }
    
    .section02c-container{
        padding-bottom: 0px;
        padding-top: 0px;
     }
    
}

/*
@media (max-width: 991px){
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .title-flex {
        flex-direction: column;
        align-items: flex-start;
    }
}
*/
