.opportunity-hub-working-at-nimber-container {
  display: flex;
  flex-direction: row;
}

.opportunity-hub-working-at-nimber-title {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.opportunity-hub-working-at-nimber-text {
  color: #171717;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  width: 40%;
}

.opportunity-hub-cant-find-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opportunity-hub-cant-find-container h1 {
  color: #171717;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 28px;
}

.opportunity-hub-cant-find-container p {
  color: #171717;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 82px;
}

.opportunity-hub-cant-find-container button {
  display: inline-flex;
  height: 56px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #fafafa;
  background: #0227b0;
  color: #fafafa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.opportunity-hub-filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px;
  margin: 40px 0;
  background-color: #fafafa;
}

/* The switch - the box around the slider */
.opportunity-hub-opportunities-checkbox {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.opportunity-hub-opportunities-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.checkbox-slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox-slide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .checkbox-slide {
  background-color: #0227b0;
}

input:focus + .checkbox-slide {
  box-shadow: 0 0 1px gray;
}

input:checked + .checkbox-slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.checkbox-slide.round {
  border-radius: 34px;
}

.checkbox-slide.round:before {
  border-radius: 50%;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
  .opportunity-hub-working-at-nimber-title,
  .opportunity-hub-working-at-nimber-text {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .opportunity-hub-working-at-nimber-container {
    flex-direction: column;
  }

  .opportunity-hub-working-at-nimber-title {
    margin: 20px 0 0 0;
  }

  .opportunity-hub-working-at-nimber-text {
    padding: 20px;
    font-size: 22px;
    margin-bottom: 40px;
  }

  .opportunity-hub-cant-find-container p {
    margin-bottom: 41px;
    font-size: 22px;
    margin: 20px;
  }

  .opportunity-hub-three-block {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .opportunity-hub-working-at-nimber-title,
  .opportunity-hub-working-at-nimber-text {
    width: 100%;
    align-items: flex-start;
  }

  .opportunity-hub-working-at-nimber-container {
    flex-direction: column;
  }

  .opportunity-hub-working-at-nimber-title {
    margin: 20px 0 0 0;
  }

  .opportunity-hub-working-at-nimber-title > .section-title {
    width: 100%;
  }

  .opportunity-hub-working-at-nimber-text {
    padding: 20px;
    font-size: 18px;
  }

  .opportunity-hub-filters {
    flex-direction: column;
    margin-top: 0;
  }

  .opportunity-hub-cant-find-container p {
    margin-bottom: 41px;
  }
}
