.offer-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.job-listing-upload-content {
  margin-top: -20px;
}

.job-listing-container,
.job-form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
}

.job-form-container {
  background-color: #fafafa;
}

.job-application-form {
  background-color: #fafafa;
  margin-top: 20px;
}

.offer-page-back-btn {
  width: 100%;
}

.offer-select-opportunity {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}

/* Styles for mobile and tablets */
@media screen and (max-width: 1023px) {
  .job-listing-container,
  .job-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }

  .offer-page-container {
    flex-direction: column;
  }

  .offer-page-back-btn {
    margin-top: 30px;
  }

  .job-listing-upload-content {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
  }
}
