.section02a {
    padding: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section02b {
    padding: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section02b-img {
    position: relative;
    top: -35px;
}

.section02a-text01 {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #171717;
}

.section02a-h2 {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #01104A;
}

.section02b-h2 {
    font-family: 'Brink-Bold', Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #01104A;
}

.section02a-text02 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #171717;
}

.section02b-text01 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #171717;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
    .section02b {
        padding: 0px 16px 16px 16px;
    }

    .section02a {
        padding: 32px 16px 16px 16px;
    }

    .section02a-text01 {
        font-size: 22px;
        padding: 30px;
    }

    .section02a-h2 {
        font-size: 26px;
    }

    .section02a-text02 {
        font-size: 18px;
    }

    .section02b-h2 {
        font-size: 26px;
    }

    .section02b-text01 {
        font-size: 18px;
    }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
    .section02b {
        padding: 0px 16px 16px 16px;
    }

    .section02a {
        padding: 32px 16px 16px 16px;
    }

    .section02a-text01 {
        font-size: 18px;
        padding: 30px;
    }

    .section02a-h2 {
        font-size: 20px;
    }

    .section02a-text02 {
        font-size: 16px;
    }

    .section02b-h2 {
        font-size: 20px;
    }

    .section02b-text01 {
        font-size: 16px;
    }
}


/*
@media (max-width: 991px){
    .section02b {
        padding: 16px;
    }

    .section02a {
        padding: 16px;
    }

    .section02a-text01 {
        padding: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){

}*/