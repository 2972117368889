.job-application-form {
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
}

.status {
  color: #000;
  font-weight: bold;
}

.salary-range {
  color: #000;
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.freelance {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.job-application-form input[type="text"],
.job-application-form input[type="email"],
.job-application-form input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.job-application-form > label {
  font-size: 1.2rem;
}

.file-upload-label {
  display: block;
  border: 2px dashed #ccc;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  margin: 10px 0;
}

.file-upload-label .browse {
  color: #0000ee;
  text-decoration: underline;
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.apply-button {
  width: 100%;
  padding: 15px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-button:hover {
  background-color: #333;
}

.file-upload-label-placeholder {
  color: #a6a6a6;
  font-size: 0.9rem;
}

/* Styles for mobile and tablets */
@media screen and (max-width: 1023px) {
  .file-upload-label {
    padding: 15px;
    margin-bottom: 20px;
  }
}

.file-uploaded-container {
  display: flex;
  margin-bottom: 20px;
  margin-top: 15px;
}

.file-uploaded {
  width: 50%;
  text-align: center;
}

.remove-file-btn {
  width: 5%;
  font-size: 16px;
  background-color: white;
  border: none;
  cursor: pointer;
}
