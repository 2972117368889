/* pagination.css */

.paginationOpportunities {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.page-item {
  padding: 15px 20px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 15px;
}

.page-item.current {
  background-color: #0227b0;
  color: #fff;
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .page-item {
    padding: 10px 15px;
    font-size: 13px;
  }
}
