.techedge-title {
    display: flex;
    justify-content: center;
}

.techedge-content {
    max-width: 100vw;
}

.techedge-subtitle {
    color:  #151515;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 16px;
}

.techedge-description-text {
    color: #151515;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.techedge-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 64px;
    gap: 24px;
    justify-content: center;
}

.slick-slider {
    width: 100%;
}

.slick-track {
    display: flex !important;
    justify-content: center !important;
    gap: 160px !important;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
    .techedge-description-text {
        font-size: 22px;
    }
    .techedge-subtitle {
        font-size: 26px;
    }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
    .techedge-description-text {
        font-size: 18px;
    }

    .techedge-subtitle {
        font-size: 22px;
    }
}

@media (max-width: 1100px) {
    .slick-track {
        display: block !important;
    }

    .techedge-content-text {
        padding: 0 16px;
    }
}

.slick-arrow {
    display: none !important;
}