.section01-text {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #171717;
    padding: 0 30px 30px 30px;
}

/* Styles for tablets */
@media screen and (min-width: 481px) and (max-width: 1023px) {
    .section01-text {
        font-size: 22px;
    }
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
    .section01-text {
        font-size: 18px;
    }
}