.tech-edge-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 64px;
    padding-bottom: 20px;
    gap: 50px;
}

.tech-edge-container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}

.tech-edge-container-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tech-edge-container-content p {
    width: 65%;
    color: #151515;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.tech-edge-container-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.tech-edge-container-button-unselected {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 1px solid #D6D6D6;
    background: #FAFAFA;

    color: #0A0A0A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.tech-edge-container-button-selected {
    padding: 12px 24px;
    border-radius: 24px;
    background: #0227B0;
    border: none;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.3px;
    cursor: pointer;
}

@media (max-width: 991px){
    .tech-edge-container-buttons {
        flex-wrap: wrap;
    }

    .tech-edge-container-content p {
        width: 100%;
        color: #151515;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding: 0 16px;
    }
}

