.icon-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #01104a;
  border-radius: 5px;
  border: 1px solid #01104a;
  margin-left: auto;
}

.icon-button:hover {
  background-color: #0227b0;
  color: white;
}

.icon-button i {
  margin-left: 10px;
}

.icon-button-blue {
  background-color: #0227b0;
  color: #fafafa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: 1px;
}

/* Styles for mobile devices */
@media screen and (max-width: 480px) {
  .icon-button {
    margin: 5px 0;
  }
}
